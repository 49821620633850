.ui.segment.no-style {
  margin: 0;
  padding: 0;
}
.ui.dimmer.inverted.loading {
  background-color: rgba(255, 255, 255, 0.3);
}

.admin-page .main-content {
  padding: 0 10px;
}
.admin-page .edit-link {
  text-align: center;
  display: block;
}
