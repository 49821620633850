/* TODO css module / sass or whatever */
body,
h1, h2, h3, h4, h5, h6,
p, a, li, span {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size-adjust: none;
  font-stretch: 100%;
  font-style: normal;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  font-weight: 400;
}

* {
  /* mac firefox issue
  firefox support since 64
  https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width */
  scrollbar-width: none;
}

.app {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .app {
    min-height: 100vh;
    height: auto;
  }
}

.button--no-style {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.content-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
@media only screen and (max-width: 768px) {
  .content-row {
    display: block;
  }
}

/* TODO refactor
these styles are used by 2 components
 */
.profession-nav {
  background: #233e6b;
}
.profession-nav .profession-nav__button {
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}
.profession-nav .profession-nav__button--active {
  pointer-events: none;
  background-color: #4472c4
}
.profession-nav .profession-nav__text {
  font-size: 14px;
  color: #ffffff;
  padding: 10px;
  margin: 0;
}

.profession-nav .profession-nav__keyword {
  font-size: 24px;
  margin-right: 10px;
}
.profession-nav .profession-nav__remarks-link {
  font-size: 10px;
  margin-left: 20px;
}
